@tailwind base;
@tailwind components;
@tailwind utilities;

/*IE11 hack*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    font-family: Arial, Helvetica, sans-serif !important;
  }

  button {
    background-color: #3a71f8 !important;
    color: white !important;
  }
}

@layer base {
  /* signature fonts */
  @font-face {
    font-family: 'Just Another Hand';
    font-weight: 400;
    src: url('../public/fonts/JustAnotherHand-Regular.woff2') format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Kristi';
    font-weight: 400;
    src: url('../public/fonts/Kristi-Regular.woff2') format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Zeyada';
    font-weight: 400;
    src: url('../public/fonts/Zeyada.woff2') format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Oooh Baby';
    font-weight: 400;
    src: url('../public/fonts/OoohBaby-Regular.woff2') format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Loved by the King';
    font-weight: 400;
    src: url('../public/fonts/LovedbytheKing.woff2') format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Square Peg';
    font-weight: 400;
    src: url('../public/fonts/SquarePeg-Regular.woff2') format('woff2');
    font-display: swap;
  }
}

sup {
  top: -0.3em;
  vertical-align: super;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
  pointer-events: none;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
